import {inject} from 'aurelia-framework';
import {CarePlanService} from "../services/CarePlanService";
import {DialogController} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";

@inject(CarePlanService, DialogController, I18N)
export class ModalDiagnosis {
    carePlanService: CarePlanService;
    dialogController: DialogController;
    i18n: I18N;

    diagnosisCodeSystem;
    conditions;

    modalHeight = 800;

    diagnoseToTasksConceptMap;

    filter;

    patient;

    constructor(carePlanService: CarePlanService, dialogController: DialogController, i18n: I18N) {
        this.carePlanService = carePlanService;
        this.dialogController = dialogController;
        this.i18n = i18n;
    }

    async activate(model) {
        this.patient = model.patient;
        this.modalHeight = window.innerHeight * 0.7;

        this.filter = model.filter
        this.diagnoseToTasksConceptMap = await this.carePlanService.getDiagnoseToTasksConceptMap(this.patient);
        this.conditions = JSON.parse(JSON.stringify(model.conditions));
        this.diagnosisCodeSystem = JSON.parse(JSON.stringify(await this.carePlanService.getDiagnoseCodeSystem(this.patient)));

        for ( let i = 0; i < this.diagnosisCodeSystem.concept.length; i++) {
            const group = this.diagnosisCodeSystem.concept[i];
            group._isShown = false;

            if (!group.concept) {
                return;
            }

            for (let j = 0; j < group.concept.length; j++) {
                const category = group.concept[j];
                category._active = '';

                if (!category.concept) {
                    return;
                }

                for (let k = 0; k < category.concept.length; k++) {
                    const subCat = category.concept[k];

                    for (let l = 0; l < this.conditions.length; l++) {
                        const condition = this.conditions[l];
                        const conditionCode = condition.code?.coding?.[0]?.code;

                        if (!conditionCode) {
                            return;
                        }

                        if (conditionCode === subCat?.code) {
                            category._active = conditionCode;
                        }

                        if (this.filter) {
                            const taskIds = await this.carePlanService.findConceptMapping(this.patient, subCat.code, this.diagnoseToTasksConceptMap)
                            category._isVisible = taskIds.includes(this.filter)
                        } else {
                            category._isVisible = true
                        }
                    }
                }

                if (!this.conditions || this.conditions.length === 0) {
                    category._isVisible = true;
                }
            }

            group._isVisible = group.concept.some((category) => category._isVisible);
        }
    }

    cancel() {
        this.dialogController.cancel();
    }

    submit() {
        if (this.isSubmitDisabled) {
            return;
        }

        const conditionCodes = [];

        this.diagnosisCodeSystem.concept.forEach((group) => {
            group.concept.forEach((category) => {
                if (category._active) {
                    const diagnose = category.concept.find((subCat) => subCat.code === category._active);

                    conditionCodes.push({
                        code: diagnose.code,
                        display: diagnose.display,
                        definition: diagnose.definition
                    });
                }
            });
        });

        this.dialogController.ok(conditionCodes);
    }

    get noMatchingDiagnoses() {
        return this.diagnosisCodeSystem.concept.every((group) => !group._isVisible)
    }

    get isSubmitDisabled() {
        let isDisabled = true;

        this.diagnosisCodeSystem.concept.forEach((group) => {
            group.concept.forEach((category) => {
                if (category._active && category._isVisible) {
                    isDisabled = false;
                }
            });
        });

        return isDisabled;
    }

    changeStatus(category, item) {
        category._active = item ? item.code : '';
    }

    toggleExpand(group, node) {
        group._isShown = !group._isShown;

        if (!group._isShown) {
            node.classList.add('collapsed');
        } else {
            node.classList.remove('collapsed');
        }
    }
}
